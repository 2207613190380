<template>
  <!--
    Displays a link that points away from the SPA.
  -->
  <div class="deviceCommandDialogExternalLink">
    <ul class="mb-0">
      <li
        v-for="(externalLink, index) in externalLinks"
        :key="`deviceCommandDialogExternalLinkDiv${ index }`"
      >
        <a
          :href="externalLink.url"
          target="_blank"
        >{{ externalLink.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DeviceCommandDialogExternalLink",
  props: {
    externalLinks: {
      type: Array,
      required: true
    }
  }
}
</script>
